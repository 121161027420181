// package imports
import React, { FC } from 'react';

// components
import TemplateLayout from '../common/TemplateLayout';
import TemplateTextCard from '../common/TemplateTextCard';

// interface
import { CommmonTemplateProps } from '../../TypesAndInterfaces';

const NoImageTemplate: FC<CommmonTemplateProps> = (props): React.JSX.Element => {
  return (
    <TemplateLayout>
      <TemplateTextCard type={'no-img'} {...props} />
    </TemplateLayout>
  );
};

export default NoImageTemplate;
