// package imports
import React, { FC } from 'react';

// components
import TemplateLayout from '../common/TemplateLayout';
import TemplateTextCard from '../common/TemplateTextCard';

// interface
import { ImageTemplateProps } from '../../TypesAndInterfaces';

const BgImageTemplate: FC<ImageTemplateProps> = (props): React.JSX.Element => {
  const { imgUrl, ...restProps } = props;

  return (
    <TemplateLayout>
      <div
        className="w-100 h-100 cover bg-center relative"
        style={{
          backgroundImage: `url(${imgUrl})`,
        }}
      >
        <div className="absolute absolute--fill o-50 bg-gradient"></div>
        <div className="absolute absolute--fill">
          <TemplateTextCard type={'bg-img'} {...restProps} />
        </div>
      </div>
    </TemplateLayout>
  );
};

export default BgImageTemplate;
