// package imports
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

// components
import { toastNotifySuccess } from '@/components/Common/Toast';

// utills and others
import { CURRENCY_SYMBOL } from 'src/config';
import { RootState } from '@/redux/reducers';

// interface
import { TemplateTextCardProps } from '../../../TypesAndInterfaces';

const TemplateTextCard: FC<TemplateTextCardProps> = (props): React.JSX.Element => {
  const logoImage = useSelector(
    (state: RootState) => state.storeReducer.store?.logo_image
  );
  const { type, greedyMessage, offerText, minOrderValue, maxDiscount, couponName } =
    props;

  const copyCoupon = () => {
    const copiedText = couponName;
    navigator.clipboard
      .writeText(copiedText)
      .then(() => {
        toastNotifySuccess('Coupon copied!');
      })
      .catch((err) => {
        console.error('Oops!! Something went wrong!', err);
      });
  };

  const getOfferLimitText = () => {
    const minOrderValueTextOnly =
      minOrderValue && `Minimum order value ${CURRENCY_SYMBOL}${minOrderValue}`;
    const maxDiscountTextOnly =
      maxDiscount && `Maximum discount ${CURRENCY_SYMBOL}${maxDiscount}`;
    const bothText =
      minOrderValue && maxDiscount && `${minOrderValueTextOnly}, ${maxDiscountTextOnly}`;

    return (
      bothText ||
      maxDiscountTextOnly ||
      minOrderValueTextOnly || ""
    );
  };

  return (
    <div
      className={`flex flex-column justify-center items-center w-100 h-100 z-1 sticky text-card text-card-${type}`}
    >
      {!!logoImage && <img src={logoImage} className="logo-image" />}
      <p className="tempting-message">{greedyMessage}</p>
      <span className="flex flex-column justify-center items-center tc offer-info">
        <h2 className="offer-text">{offerText}</h2>
        <p className="offer-limit">{getOfferLimitText()}</p>
      </span>
      <div className="flex flex-column coupon-button-wrapper">
        <div className="flex justify-center w-100 coupon-name">{couponName}</div>
        <button className="copy-coupon-button" onClick={copyCoupon}>
          Copy code
        </button>
      </div>
    </div>
  );
};

export default TemplateTextCard;
