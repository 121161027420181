// package imports
import React, { FC } from 'react';

// components
import TemplateLayout from '../common/TemplateLayout';
import TemplateTextCard from '../common/TemplateTextCard';

// interface
import { ImageTemplateProps } from '../../TypesAndInterfaces';

const SideImageTemplate: FC<ImageTemplateProps> = (props): React.JSX.Element => {
  const { imgUrl, ...restProps } = props;

  return (
    <TemplateLayout>
      <div
        className="w-50 h-100 cover bg-center"
        style={{
          backgroundImage: `url(${imgUrl})`,
        }}
      ></div>
      <div className="w-50 h-100">
        <TemplateTextCard type={'side-img'} {...restProps} />
      </div>
    </TemplateLayout>
  );
};

export default SideImageTemplate;
