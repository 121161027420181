import { Dialog } from '@mui/material';
import {
  getLocalExitGatingConfig,
  getTemplateByType,
  getTemplateInfo,
  setLocalExitGatingConfig,
} from './utils';
import Close from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExitGatingData } from '@/redux/actions';
import { RootState } from '@/redux/reducers';
import { EXIT_GATING_TEMPLATE_TYPE } from './constants';
import React from 'react';
import { isNumber } from 'lodash';

const ExitGatingModal = () => {
  const {
    exitGatingData: { selectedTemplate, status, minUserTimeSpent, perDayPopUpLimit },
    storeId,
  } = useSelector((state: RootState) => ({
    exitGatingData: state.commonReducer.exitGatingData,
    storeId: state.storeReducer.store.store_id,
  }));
  const dispatch = useDispatch();

  const [timerId, setTimerId] = useState(null);
  const [exitGatingConfig, setExitGatingConfig] = useState({
    totalPopUpsShown: null,
    timeSpent: null,
    date: new Date().toDateString(),
  });

  const perDayPopUpLimitReached = exitGatingConfig.totalPopUpsShown >= perDayPopUpLimit;

  const [showModal, setShowModal] = useState(false);
  const [modalCanBeShown, setModalCanBeShown] = useState(false);

  const isBgImgTypeModal = selectedTemplate === EXIT_GATING_TEMPLATE_TYPE.BG_IMG;
  const Template = getTemplateByType(selectedTemplate);

  useEffect(() => {
    document.body.addEventListener('mouseleave', handleOnMouseLeave);
    return () => {
      document.body.removeEventListener('mouseleave', handleOnMouseLeave);
    };
  }, [modalCanBeShown, exitGatingConfig.totalPopUpsShown]);

  useEffect(() => {
    handleIntialData();
    return () => {
      stopTimer();
    };
  }, [status, storeId]);

  useEffect(() => {
    checkIfModalCanBeShown();
    return () => {
      saveConfigInLocal();
    };
  }, [exitGatingConfig.timeSpent, exitGatingConfig.totalPopUpsShown]);

  // checks if the modal can be shown on webpage on mouse leave
  const checkIfModalCanBeShown = () => {
    const { timeSpent } = exitGatingConfig;
    if (!perDayPopUpLimitReached) {
      if (timeSpent > minUserTimeSpent * 60) {
        setModalCanBeShown(true);
      } else {
        modalCanBeShown && setModalCanBeShown(false);
      }
    } else {
      saveConfigInLocal();
      stopTimer();
    }
  };

  const saveConfigInLocal = () =>
    isNumber(exitGatingConfig.timeSpent) &&
    isNumber(exitGatingConfig.totalPopUpsShown) &&
    setLocalExitGatingConfig(exitGatingConfig);


  // Action when mouse leaves the webpage
  const handleOnMouseLeave = () => {
    if (modalCanBeShown && !perDayPopUpLimitReached) {
      setShowModal(true);
      setExitGatingConfig((state) => ({
        ...state,
        totalPopUpsShown: state.totalPopUpsShown + 1,
        timeSpent: 0,
      }));
      setModalCanBeShown(false);
    }
  };

  // handle initial data for exit Gating
  const handleIntialData = () => {
    if (!status) {
      storeId && dispatch(getExitGatingData(storeId));
    } else {
      const localExitGatingConfig = getLocalExitGatingConfig();
      if (localExitGatingConfig?.date === exitGatingConfig.date) {
        setExitGatingConfig((state) => ({
          ...state,
          ...localExitGatingConfig,
        }));
      } else {
        setExitGatingConfig((state) => ({
          ...state,
          totalPopUpsShown: 0,
          timeSpent: 0,
        }));
      }
      !timerId && startTimer();
    }
  };

  // Start the timmer
  const startTimer = () => {
    if (timerId) {
      clearInterval(timerId);
    }
    const thisTimerId = setInterval(() => {
      setExitGatingConfig((state) => ({ ...state, timeSpent: state.timeSpent + 1 }));
    }, 1000);
    setTimerId(thisTimerId);
  };

  // stop the timer
  const stopTimer = () => {
    if (timerId) {
      clearInterval(timerId);
      setTimerId(null);
    }
  };

  const toggleModal = () => setShowModal(!showModal);

  return (
    <Dialog
      open={showModal}
      PaperProps={{
        style: {
          padding: '0px',
          borderRadius: '0px',
        },
      }}
      maxWidth="lg"
      onClose={toggleModal}
    >
      <div className="relative h-100 w-100">
        <Close
          className={`absolute right-1 top-1 exit-modal-close-icon ${
            isBgImgTypeModal ? 'exit-modal-close-icon-white' : ''
          }`}
          onClick={toggleModal}
        />
        <Template {...getTemplateInfo(selectedTemplate)} />
      </div>
    </Dialog>
  );
};

export default ExitGatingModal;
