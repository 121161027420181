import store from "@/redux/store";
import { CURRENCY_SYMBOL } from 'src/config';
import { BgImageTemplate, NoImageTemplate, SideImageTemplate } from "../components";
import {
  EXIT_GATING_CONFIG_KEY,
  EXIT_GATING_DEFAULT_IMG_URL,
  EXIT_GATING_GREEDY_MESSAGE,
  EXIT_GATING_TEMPLATE_TYPE,
  PERCENTAGE_DISCOUNT_TYPE_CONSTANT,
} from "../constants";
import { TemplateType } from "../TypesAndInterfaces";
import LocalStorageHelper from "@/utils/LocalStorageHelper";

export const getTemplateGreedyMessage = (templateType: TemplateType) => {
  const { BG_IMG, SIDE_IMG } = EXIT_GATING_TEMPLATE_TYPE;
  switch (templateType) {
    case BG_IMG:
      return EXIT_GATING_GREEDY_MESSAGE.BG_IMG;
    case SIDE_IMG:
      return EXIT_GATING_GREEDY_MESSAGE.SIDE_IMG;
    default:
      return EXIT_GATING_GREEDY_MESSAGE.NO_IMG;
  }
};

export const getTemplateOfferText = (couponData: any) => {
  const isCouponPercentType = couponData?.discount_type === PERCENTAGE_DISCOUNT_TYPE_CONSTANT;
  return `GET ${isCouponPercentType ? `${couponData?.discount}%` : `FLAT ${CURRENCY_SYMBOL}${couponData?.discount}`}  OFF`;
};

export const getTemplateInfo = (templateType: TemplateType) => {
  const exitGatingData = store.getState().commonReducer.exitGatingData;
  const imgUrl = exitGatingData?.imagePath || EXIT_GATING_DEFAULT_IMG_URL;
  const couponData = exitGatingData?.couponData;

  const shouldIncludeImg = templateType !== EXIT_GATING_TEMPLATE_TYPE.NO_IMG;
  const commonInfo = {
    greedyMessage: getTemplateGreedyMessage(templateType),
    offerText: getTemplateOfferText(couponData),
    minOrderValue: couponData?.min_order_price,
    maxDiscount: couponData?.max_discount,
    couponName: couponData?.promo_code || "",
  };
  return shouldIncludeImg ? { ...commonInfo, imgUrl } : commonInfo;
};

export const getTemplateByType = (templateType: TemplateType) => {
  const { BG_IMG, SIDE_IMG } = EXIT_GATING_TEMPLATE_TYPE;
  switch (templateType) {
    case BG_IMG:
      return BgImageTemplate;
    case SIDE_IMG:
      return SideImageTemplate;
    default:
      return NoImageTemplate;
  }
};

export const getLocalExitGatingConfig = () => LocalStorageHelper.get(EXIT_GATING_CONFIG_KEY);
export const setLocalExitGatingConfig = (data: any) => LocalStorageHelper.add(EXIT_GATING_CONFIG_KEY, data);
